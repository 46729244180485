<template>
  <div class="camera-btn bg-gray-030">
    <img
      class="camera-icon"
      src="/assets/images/svg/ic_camera_thin.svg"
      width="21"
    />
  </div>
</template>

<script>
export default {
  name: "CameraButton",
};
</script>

<style src="./style.css" scoped></style>
