<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>
          {{ state.pollOptionResourceId ? "선택지 수정" : "선택지 생성" }}
        </h3>
      </div>
    </template>

    <template #modalBody>
      <div class="info-form">
        <div>
          <h5>선택지명</h5>
          <input-basic-new
            v-for="(item, index) in [
              { lang: 'ko', label: '한국어' },
              { lang: 'en', label: '영어' },
              { lang: 'ja', label: '일본어' },
              { lang: 'zh_cn', label: '중국어(간체)' },
              { lang: 'zh_tw', label: '중국어(번체)' },
            ]"
            :key="`lang-${index}`"
            class="title"
            :label="`${item.label}`"
            :required="item.lang === 'ko'"
            :default-value="state.pollForm.localizations[item.lang]['title']"
            @updateData="
              (value) =>
                actions.updateLocalizationsOfPollForm(item.lang, 'title', value)
            "
          >
          </input-basic-new>
        </div>
        <div class="feature-img">
          <h5>대표 이미지</h5>
          <div
            class="featured-img"
            :style="`background-image: url(${state.pollForm.featuredImage});`"
          >
            <input
              ref="fileInput"
              type="file"
              hidden
              @change="actions.addAttachment($event)"
            />
            <div class="img-upload">
              <camera-button
                @click="actions.openFileSelector()"
              ></camera-button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          text-size="s1"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          class="save"
          :text="state.pollOptionResourceId ? '수정' : '저장'"
          text-size="s1"
          :disabled="!state.isActiveSaveBtn"
          @action="
            state.pollOptionResourceId
              ? actions.editPoll()
              : actions.createPoll()
          "
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { reactive, computed, getCurrentInstance, onMounted, ref } from "vue";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputBasicNew from "../../../../components/console/inputs/InputBasicNew";
import CameraButton from "@/pages/console/EntClubs/CameraButton/CameraButton.vue";
import { useStore } from "vuex";
import ApiService from "@/api";

export default {
  name: "PollOptionsCreateModal",
  components: {
    CameraButton,
    ButtonBasic,
    BaseModalSide,
    InputBasicNew,
  },
  props: {
    resourceId: {
      type: String,
      required: true,
    },
    pollOptionsItem: {
      type: Object,
      required: false,
    },
  },
  emits: ["hideModal", "saveComplete", "updateData"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const fileInput = ref();
    const store = useStore();

    const state = reactive({
      pollForm: {
        title: "",
        featuredImage: proxy.$const.blankImage,
        localizations: {
          ko: { title: "" },
          en: { title: "" },
          ja: { title: "" },
          zh_cn: { title: "" },
          zh_tw: { title: "" },
        },
      },
      pollResourceId: "",
      pollOptionResourceId: "",
      isActiveSaveBtn: computed(() => {
        return state.pollForm?.localizations["ko"]["title"].length > 0;
      }),
      imageFile: null,
    });

    onMounted(() => {
      state.pollResourceId = props.resourceId;
      let pollOptionsItem = props.pollOptionsItem;
      console.log("pollOptionsItem", pollOptionsItem);
      if (pollOptionsItem) {
        state.pollOptionResourceId = pollOptionsItem.resourceId;
        state.pollForm.featuredImage = pollOptionsItem.featuredImage;

        Object.keys(state.pollForm.localizations).forEach((key) => {
          if (
            pollOptionsItem.localizations[key] &&
            pollOptionsItem.localizations[key].title !== ""
          ) {
            state.pollForm.localizations[key].title =
              pollOptionsItem.localizations[key].title;
          }
        });
      }
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      updateLocalizationsOfPollForm: (lang, formKey, value) => {
        state.pollForm.localizations[lang][formKey] = value;
      },
      createPoll: async () => {
        let payload = { localizations: state.pollForm.localizations };
        console.log(state.pollForm.localizations);

        console.log("state.imageFile", state.imageFile);
        if (state.imageFile) {
          const formData = new FormData();
          formData.append("file", state.imageFile);
          const res = await ApiService.postAttachment(formData);
          console.log(res);
          payload.featuredImage = res.data.data.path;
        }
        emit("saveComplete", payload);
      },
      editPoll: () => {
        console.log("poll-options-state.pollForm", state.pollForm);
        emit("updateData", state.pollForm);
      },
      addAttachment: (e) => {
        // todo 수정버튼 누르는 순간으로 교체하도록 수정 필요.
        state.fileUploading = true;
        const files = e.target.files || e.dataTransfer.files;

        const formData = new FormData();
        formData.append("file", files[0]);
        // 이미 생성된 옵션의 경우, 이미지 수정, 생성하는 옵션은 이미지와 타이틀을 같이 보냄.
        if (state.pollOptionResourceId) {
          let payload = {
            pollResourceId: state.pollResourceId,
            pollOptionResourceId: state.pollOptionResourceId,
            data: formData,
          };
          store
            .dispatch("polls/postPollOptionsFeatureImage", payload)
            .then((response) => {
              const result = response.data;
              if (result.success) {
                state.pollForm.featuredImage = result.data.featuredImage;
              }
            });
        } else {
          state.imageFile = files[0];
          // 선택된 파일 가져오기
          const selectedFile = files[0];

          // 파일을 읽기 위한 FileReader 객체 생성
          const reader = new FileReader();

          // 파일 읽기가 완료되었을 때의 이벤트 핸들러 등록
          reader.onload = function () {
            // 읽은 파일의 데이터 URL을 img 요소의 src 속성에 설정하여 이미지 미리보기 표시
            state.pollForm.featuredImage = reader.result;
          };

          // 파일을 읽기
          reader.readAsDataURL(selectedFile);
        }
      },
      openFileSelector: () => {
        fileInput.value.click();
      },
    };

    return { state, actions, fileInput };
  },
};
</script>

<style src="./style.css" scoped></style>
