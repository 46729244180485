<template>
  <div class="poll-options-item">
    <div v-show="isSorting" class="event-barrier"></div>
    <div class="info">
      <button v-if="draggable" class="handle handler-btn">
        <handler-icon fill-color="#818287"></handler-icon>
      </button>

      <div
        class="featured-img"
        :style="`background-image: url(${poll.featuredImage}); min-width:70px; width: 70px; height: 70px`"
      ></div>

      <div class="meta-info">
        <p class="title text-default">
          <!--          todo computed로 정리-->
          {{ poll.localizations?.ko && poll.localizations?.ko.title }}
        </p>
        <div class="vote-count">
          <p class="text-gray-second sub-text-s2">
            득표수 : {{ poll.voteCount }}
          </p>
          <p class="text-gray-second sub-text-s2">
            득표율 : {{ poll.votePercentage }}%
          </p>
        </div>
      </div>
      <button-dropdown distance="0" class="button-drop-down">
        <template #button>
          <button>
            <more-verti-icon width="16" height="16"></more-verti-icon>
          </button>
        </template>
        <template #dropdownList>
          <ul class="drop-down-list">
            <li>
              <button
                v-close-popper
                class="btn text-default sub-text-s2"
                @click="actions.openEditModal()"
              >
                <edit-icon fill-color="#0D0D10"></edit-icon>
                수정
              </button>
            </li>
            <li>
              <button
                v-close-popper
                class="btn text-red-50 sub-text-s2"
                @click="actions.openDeleteModal()"
              >
                <delete-icon fill-color="#FF4646"></delete-icon>
                삭제
              </button>
            </li>
          </ul>
        </template>
      </button-dropdown>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive } from "vue";
import HandlerIcon from "@/components/console/icons/HandlerIcon.vue";
import DeleteIcon from "@/components/console/icons/DeleteIcon.vue";
import EditIcon from "@/components/console/icons/EditIcon.vue";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import ButtonDropdown from "@/components/console/buttons/ButtonDropdown.vue";

export default {
  name: "PollCandidateItem",
  components: {
    ButtonDropdown,
    MoreVertiIcon,
    EditIcon,
    DeleteIcon,
    HandlerIcon,
  },
  props: {
    poll: {
      type: Object,
      required: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    isSorting: {
      type: Boolean,
      default: false,
    },
    voteCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ["openDeleteModal", "openEditModal"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const featuredImageAccepts = ["jpg", "jpeg", "png"];

    const state = reactive({
      candidateForm: {},
    });

    const actions = {
      openEditModal: () => {
        emit("openEditModal");
      },
      openDeleteModal: () => {
        emit("openDeleteModal");
      },
    };

    return { state, actions, featuredImageAccepts };
  },
};
</script>

<style src="./style.css" scoped></style>
