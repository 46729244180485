<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>투표 화이트 리스트 설정</h3>
        <p class="sub-text-s2 text-gray-second">
          허용할 국가를 선택하여 화이트 리스트에 추가하세요.
        </p>
      </div>
    </template>

    <template #modalBody>
      <div class="geo-fence-form">
        <div class="selected-country">
          <h4 class="sub-text-s2">화이트 리스트</h4>
          <ul>
            <li
              v-for="(item, index) in state.selectedCountryList"
              :key="`${item}-${index}`"
            >
              <button
                class="sub-text-s3 tag-btn"
                @click="
                  () => {
                    actions.removeCountry(item);
                  }
                "
              >
                {{ `${item}` }}
                <span>
                  <close-icon></close-icon>
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div class="country-list">
          <div>
            <h4 class="sub-text-s2">화이트 리스트 추가</h4>
            <div class="search-wrapper">
              <input-search-new
                v-model="state.query"
                class="search"
                placeholder="국가명을 입력해주세요."
                :search-type="'typingText'"
                @search="actions.searchCountry"
              ></input-search-new>
            </div>
          </div>
          <ul>
            <li
              v-for="(item, index) in state.countrylist"
              :key="`${item}-${index}`"
            >
              <button
                class="sub-text-s3"
                @click="
                  () => {
                    actions.selectCountry(item);
                  }
                "
              >
                {{ `${item}` }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          text-size="s1"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          class="save"
          text="저장"
          text-size="s1"
          :is-loading="state.isSaving"
          :disabled="!state.isActiveSaveBtn"
          @action="actions.createGeoFence"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { reactive, computed, onBeforeMount } from "vue";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ApiService from "@/api";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import InputSearchNew from "@/components/console/inputs/InputSearchNew.vue";
import { cloneDeep } from "lodash";

export default {
  name: "GeoFenceCreateModal",
  components: {
    InputSearchNew,
    CloseIcon,
    ButtonBasic,
    BaseModalSide,
  },
  props: {
    whiteListCountry: {
      type: Array,
      required: true,
    },
  },
  emits: ["hideModal", "saveComplete"],
  setup(props, { emit }) {
    const state = reactive({
      isActiveSaveBtn: computed(() => {
        return !state.isSaving;
      }),
      countrylist: [],
      testList: [],
      selectedCountryList: [],
      query: "",
      storedCountryList: [],
      countryOriginalData: [],
      isSaving: false,
    });

    onBeforeMount(async () => {
      const whiteList = props.whiteListCountry;
      await ApiService.getCountries().then((res) => {
        state.countryOriginalData = res.data.data;
        if (whiteList.length === 0) {
          for (const element of state.countryOriginalData) {
            state.storedCountryList.push(element.name);
          }
        } else {
          let copy = cloneDeep(res.data.data);
          whiteList.forEach((item) => {
            state.selectedCountryList.push(item.country.name);
            // reduce로 해보기

            let index = copy.findIndex((test) => {
              return test.code === item.country.code;
            });
            copy.splice(index, 1);
          });
          copy.forEach((item) => {
            state.storedCountryList.push(item.name);
          });
        }
        state.countrylist = state.storedCountryList;
      });
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      createGeoFence: () => {
        state.isSaving = true;
        const countries = [];
        state.selectedCountryList.forEach((countryName) => {
          let selectedCountry = state.countryOriginalData.find((test) => {
            return test.name === countryName;
          });
          if (selectedCountry) {
            countries.push(selectedCountry.code);
          }
        });

        emit("saveComplete", countries);
      },
      selectCountry: (item) => {
        let countryListIndex = state.countrylist.findIndex((country) => {
          return country === item;
        });
        let storedCountryListIndex = state.storedCountryList.findIndex(
          (country) => {
            return country === item;
          }
        );

        state.selectedCountryList.push(item);
        state.countrylist.splice(countryListIndex, 1);
        state.storedCountryList.splice(storedCountryListIndex, 1);
      },
      removeCountry: (item) => {
        let index = state.selectedCountryList.findIndex((country) => {
          return country === item;
        });
        const query = state.query;
        if (query.trim().length > 0) {
          // let index;
          // index = state.selectedCountryList.forEach((countryName) => {
          //   return countryName === item;
          // });

          let isValue = state.selectedCountryList.findIndex((countryName) => {
            return countryName.toLowerCase().includes(query);
          });
          if (isValue !== -1) {
            state.countrylist.push(item);
          }
        }
        state.storedCountryList.push(item);
        state.selectedCountryList.splice(index, 1);
      },
      searchCountry: () => {
        const tempCountryList = state.storedCountryList;
        const query = state.query.toLowerCase();
        if (query.trim().length > 0) {
          state.countrylist = tempCountryList.filter((country) => {
            return country.toLowerCase().includes(query);
          });
        } else {
          state.countrylist = tempCountryList;
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
